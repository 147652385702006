import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_API,
  credentials: 'include',
  prepareHeaders: (headers) => {
    headers.set('authorization', `${process.env.NEXT_PUBLIC_AUTH_KEY}`);
    return headers;
  },
});

export const apiHooks = createApi({
  baseQuery,
  tagTypes: ['Feed', 'Explore', 'Profile'],
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: (page) => `/posts/page/${page}`,
      providesTags: ['Feed'],
    }),
    getBookmarks: builder.query({
      query: (userId) => `/bookmarks/user/${userId}`,
      providesTags: ['Feed'],
    }),
    deleteBookmark: builder.mutation({
      query: (bookmarkId) => ({
        url: `/bookmarks/${bookmarkId}`,
        method: 'DELETE',
      }),
    }),
    getTagPosts: builder.query({
      query: (tagId) => `/tags/${tagId}`,
      providesTags: ['Explore'],
    }),
    getUserPosts: builder.query({
      query: (userId) => `/users/${userId}/posts`,
      providesTags: ['Profile'],
    }),
    getUser: builder.query({
      query: (userId) => `/users/${userId}`,
    }),
  }),
});

export const {
  useGetPostsQuery,
  useGetBookmarksQuery,
  useDeleteBookmarkMutation,
  useGetTagPostsQuery,
  useGetUserPostsQuery,
  useGetUserQuery,
} = apiHooks;
