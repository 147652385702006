/* eslint-disable react/prop-types */
import '@styles/styles.css';
import '../styles/nprogress.css';

import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { makeStore } from '../redux/store';
import { Provider } from 'react-redux';

import { SessionProvider } from 'next-auth/react';
import { Analytics } from '@vercel/analytics/react';

import NProgress from 'nprogress';
import ErrorBoundary from '@components/global/errorBoundary';
import { requestNotificationPermission } from '@utils/notifications';

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeComplete', () => NProgress.done());
    router.events.on('routeChangeError', () => NProgress.done());
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem('userid') &&
      !localStorage.getItem('deviceToken')
    ) {
      requestNotificationPermission();
    }
  }, []);

  return (
    <Provider store={makeStore}>
      <SessionProvider session={session}>
        <ErrorBoundary>
          <Component {...pageProps} />
          <Analytics />
          <ToastContainer
            position="top-left"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ErrorBoundary>
      </SessionProvider>
    </Provider>
  );
}

export default MyApp;
