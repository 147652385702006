import messageBanner from '@components/reusable/messageBanner';
import { getToken, getMessaging } from 'firebase/messaging';
import app from './firebase';
import axios from './api';

export async function requestNotificationPermission() {
  try {
    const permissionResult = await Notification.requestPermission();
    if (permissionResult === 'granted') {
      storeDeviceToken();
    }
  } catch (error) {
    messageBanner({
      status: 'error',
      message: 'Error while requesting notification permission',
    });
  }
}
const savedNewProfile = async (obj) => {
  const userId = localStorage.getItem('userid');
  try {
    // eslint-disable-next-line no-unused-vars
    const data = await axios.put(`/users/${userId}`, obj);
  } catch (error) {
    console.log(error);
    messageBanner({
      message: `Something went wrong, try again later 😓`,
      status: 'error',
    });
  }
};

// Function to get the device token
async function getDeviceToken() {
  try {
    const messaging = getMessaging(app);

    if (
      typeof window !== 'undefined' &&
      'serviceWorker' in navigator &&
      // @ts-ignore
      window.workbox !== undefined
    ) {
      // run only in browser
      navigator.serviceWorker.ready.then((reg) => {
        reg.pushManager.getSubscription().then((sub) => {
          if (
            sub &&
            !(
              sub.expirationTime &&
              Date.now() > sub.expirationTime - 5 * 60 * 1000
            )
          ) {
            console.log('Already subscribed', sub);
          }
        });
        getToken(messaging, {
          vapidKey: process.env.NEXT_PUBLIC_WEB_PUSH_KEY,
          serviceWorkerRegistration: reg,
        })
          .then((currentToken) => {
            if (currentToken) {
              const userId = localStorage.getItem('userid');
              if (!userId) return;

              // save device token to database
              savedNewProfile({ deviceToken: currentToken })
                .then(() => {
                  console.log('Device token saved');
                })
                .catch(() => {
                  messageBanner({
                    status: 'error',
                    message: 'Error saving device token',
                  });
                });
            } else {
              messageBanner({
                status: 'error',
                message:
                  'No registration token available. Request permission to generate one.',
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  } catch (error) {
    messageBanner({
      status: 'error',
      message: 'Error getting device token',
    });
    return null;
  }
}

// Call this function when the user logs in or grants permission for notifications
export async function storeDeviceToken() {
  const deviceToken = await getDeviceToken();
  if (deviceToken) {
    localStorage.setItem('deviceToken', deviceToken);
  }
}

export async function createNotificationPush(data, token) {
  const pushPayload = {
    title: data.title,
    message: data.description,
  };

  // Send the push notification
  try {
    const res = await fetch(
      `https://fcm.googleapis.com/v1/projects/${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}/messages:send`,
      {
        method: 'POST',
        headers: {
          // Authorization: `key=${process.env.NEXT_PUBLIC_FIREBASE_SERVER_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: token,
          notification: pushPayload,
        }),
      },
    );
    if (res.ok) {
      console.log('Push notification sent successfully');
    } else {
      console.log('failed with status code ', res.status);
    }
  } catch (error) {
    console.error('Error sending push notification:', error);
  }
}
