import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API,
});

// Set the AUTH token for any request
instance.interceptors.request.use((config) => {
  config.headers.authorization = process.env.NEXT_PUBLIC_AUTH_KEY;

  return config;
});

export default instance;
