import styled, { keyframes } from 'styled-components';
export const AnimateFour = keyframes`
0%{
    background: radial-gradient(
    circle,
    rgba(25, 69, 71, 1) 7%,
    rgba(21, 32, 43, 1) 74%
  );
}
100%{
  background: radial-gradient(
    circle,
    rgba(25, 69, 71, 1) 10%,
    rgba(21, 32, 43, 1) 40%
  );
}`;
export const WrapPage = styled.main`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 100vh;
  background: rgb(25, 69, 71);
  background: radial-gradient(
    circle,
    rgba(25, 69, 71, 1) 7%,
    rgba(21, 32, 43, 1) 74%
  );
  animation: ${AnimateFour} 5s ease-in infinite;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: #f1f1f1;
  height: 5vh;
  /* overflow: hidden; */

  @media screen and (max-width: 900px) {
    flex-direction: column;
    height: 20vh;
    gap: 5px;
  }
`;
export const H1 = styled.h1`
  text-align: center;
`;

export const VerticalLine = styled.div`
  width: 2px;
  height: 100%;
  background: #f1f1f1;
  background: linear-gradient(to bottom, #194547, #c6d1d1);
`;
export const P = styled.p``;

export const GoHomeBtn = styled.button`
  // height: 40px;
  // width: 100px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
